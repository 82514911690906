<template>
  <div class="sbFlex nav">
    <div class="header_logo" style="margin-left: 50px;display: flex">
      <img class="logo" src="../assets/images/index/menuIcon.png" style="" />
    </div>
    <div class="ml-5">
      <el-menu router
        :default-active="$route.path" 
        class="el-menu-demo" 
        mode="horizontal" 
        @select="handleSelect"
        text-color="#FFFFFF" 
        background-color="#000000"
        active-text-color="#FFFFFF">
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <!-- <el-submenu index="2">
          <template slot="title">关于我们</template>
          <el-menu-item index="/company">公司介绍</el-menu-item>
          <el-menu-item index="2-2">企业文化</el-menu-item>
          <el-menu-item index="2-2">品牌介绍</el-menu-item>
          <el-menu-item index="2-2">企业荣誉</el-menu-item>
        </el-submenu> -->
        <el-menu-item index="/product">品牌产品</el-menu-item>
        <el-menu-item index="/recruitment">招聘信息</el-menu-item>
        <el-menu-item index="/relation">联系我们</el-menu-item>
        <el-menu-item>
          <div class="phone">
            <!-- <img src="../assets/images/index/phone.png" alt="" width="22px" height="22px"> -->
            <i class="el-icon-phone"></i>
            <span style="padding-left: 5px;color:white">400-889-7632</span></div>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navStyle: {
      type: Boolean,
    }
  },
  data() {
    return {
      activeIndex: '/',
      navCss: null
    };
  },
  computed: {
    isShow() { 
      return this.navStyle
    }
  },
  watch: {
    isShow(newV) {
      // do something
      console.log(newV)
      this.navCss = newV
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      switch (keyPath) {
        case '0':
          console.log(keyPath)
          return;
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style>
.nav {
  /* padding: 0 120px 0 80px; */
  width: 100%;
  z-index: 99;
}

.logo {
  margin: auto 0;
  margin-top: 8px;
  height: 40px;
}

.navTetx {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  padding-left: 15px;
  height: 45px;
  line-height: 45px;
  color: #000;
}

.el-menu.el-menu--horizontal {
  border: 0 !important;
}

.el-menu-item {
  padding: 0 20px;
  width: auto;
  text-align: center;
  background-color: rgba(0, 0, 0, .5) !important;
}
.el-menu-item:hover {
  /* background: rgba(255, 255, 255, 0) !important; */
  color: #FFFFFF !important;
}

.el-submenu__title {
  color: red;
}
.el-menu--horizontal {
  width: 100% !important;
  left: 0 !important;
}
.el-menu--popup {
  width: 100%;
  background-color: rgba(255,255,255,.7) !important;
  margin-top: 0 !important;
  padding: 0 !important
}
.is-active {
  border-bottom: 0 !important;
}
.el-submenu__title i {
  width: 0;
}

.ml-5 {
  margin-left: 50px;
}

@media screen and (max-width: 940px) {
  .header_logo {

  }
  .ml-5 {
    margin-left: 10px;
  }
  .el-menu-item {
      padding: 0 5px !important;
      width: auto;
      text-align: center;
      background-color: rgba(0, 0, 0, .5) !important;
    }
}
</style>