<template>
    <div style="background-color: #262D3D;z-index: 9;" class="footer">
        <div class="sbFlex regionBox">
            <div class="flex_1">
                <div class="right">
                    <div class="regionTitle">联系方式</div>
                    <div class="flex">
                        <img class="regionIcon" src="../assets/images/index/bottomPhone.png" alt="" width="16px" height="16px">
                        <p>400-889-7632</p>
                    </div>
                    <div class="flex">
                        <img class="regionIcon" src="../assets/images/index/email.png" alt="" width="16px" height="16px">
                        <p>1523129192@qq.com</p>
                    </div>
                </div>
            </div>
            <div class="flex_1">
                <div class="right">
                    <!-- <div class="regionTitle">主营产品</div> -->
                    <div class="flex">
                        <div style="margin-right: 30px;">
                            <p>关于我们</p>
                            <p>品牌产品</p>
                        </div>
                        <div>
                            <p>招聘信息</p>
                            <p>联系我们</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="flex_1">
                <img src="../assets/images/index/bottomQr.jpg" alt="" width="130px" height="130px">
            </div> -->
        </div>

        <!-- 分割线 -->
        <div class="cutOff"></div>
        <p class="putOnRecords">Copyright @ 2018-2023 陕西雅泊蔻化妆品公司 版权所有 <span class="archival" @click="archivalInformation()">蜀ICP备19012959号</span></p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            phone: null
        }
    },
    methods: {
        archivalInformation() {
            window.open('https://beian.miit.gov.cn/', '_blank')
        },
    }
}
</script>

<style>
/* @media screen and (max-width: 940px) { */
    /* #html {
        width: 100%;
    }
    #app {
        margin: 0;
        width: 100%;
    } */
    
    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .regionBox {
        height: auto;
        margin: 0 50px;
        padding: 30px 0 10px;
        width: 1200px;
        width: 100%;
    }
    .regionTitle {
        font-size: 16px;
        padding-bottom: 20px;
        font-weight: 500;
    }
    .regionIcon {
        margin: auto 10px auto 0;
    }
    .right {
        width: 300px;
        margin: auto;
        text-align: left;
        font-family: Alibaba PuHuiTi;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 21px;
    }
    .right p {
        font-size: 14px;
        line-height: 30px;
    }
    .qcode {
        margin: auto;
        height: 114px;
        width: 114px;
    }
    .qcode img {
        height: 114px;
        width: 114px;
    }
    .qcode p {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }
    .cutOff {
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
    }
    .putOnRecords {
        padding: 10px 0;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #A4A4A7;
    }
    .archival:hover {
        cursor: pointer;
        color: #d51b51;
    }
/* } */
@media screen and (max-width: 940px) {
    #html {
        width: 100%;
    }
    #app {
        margin: 0;
        width: 100%;
    }
    .footer {
        position: fixed;
        bottom: 0;
        width: 100vh;
    }
    .leaveBox {
        display: grid;
        margin-left: 40px !important;
        width: 100%;
    }

    .regionBox {
        height: auto;
        margin: 0 auto;
        width: 840px;
        padding: 30px 0 30px 50px;
    }
    .regionTitle {
        font-size: 16px;
        padding-bottom: 20px;
        font-weight: 500;
    }
    .regionIcon {
        margin: auto 10px auto 0;
    }
    .right {
        width: 200px;
        margin: auto;
        text-align: left;
        font-family: Alibaba PuHuiTi;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 21px;
    }
    .right p {
        font-size: 14px;
        line-height: 30px;
    }
    .qcode {
        margin: auto;
        height: 114px;
        width: 114px;
    }
    .qcode img {
        height: 114px;
        width: 114px;
    }
    .qcode p {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }
    .cutOff {
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
    }
    .putOnRecords {
        padding: 10px 40px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #A4A4A7;
    }
}
</style>