import request from "@/utils/request";

// 查询轮播图
export function getBannerList() {
    return request({
      url: "/cro/slider/list",
      method: "GET"
    });
}

// 查询当季推荐
export function recommendationsList() {
    return request({
      url: "/cro/recommendations/list",
      method: "GET"
    });
}

// 查询当季推荐
export function recommendList() {
  return request({
    url: "/cro/product/list",
    method: "GET"
  });
}

// 查询公司动态列表
export function getNewsList() {
    return request({
      url: "/cro/news/list",
      method: "GET"
    });
}
// 查询公司动态详情
export function getNewsDetails(id) {
    return request({
      url: "/cro/news/" + id,
      method: "GET"
    });
}

// 查询公司荣誉
export function getHonorList() {
  return request({
    url: "/cro/honor/list",
    method: "GET"
  });
}

// 查询产品分类
export function getProductType() {
    return request({
      url: "/cro/center/productType",
      method: "GET"
    });
}
// 查询产品列表
export function getProductList(data) {
    return request({
      url: "/cro/center/list/?productType=" + data,
      method: "GET"
    });
}
// 查询产品详情
export function getProductDetails(id) {
    return request({
      url: "/cro/center/" + id,
      method: "GET"
    });
}

// 查询招聘信息
export function getPostingList() {
    return request({
      url: "/cro/posting/list",
      method: "GET"
    });
}

// 留言
export function messages(data) {
    return request({
      url: "/cro/messages",
      method: "POST",
      data: data
    });
}