<template>
  <div class="hello">
    <navbar />
    
    <el-carousel
      height="800px"
      arrow="never"
      background-color="#FFFFFF" 
      indicator-position="none"
      class="banner">
      <el-carousel-item v-for="(item,index) in bannerList" :key="index">
        <img v-lazy="item.imageUrl" alt="" width="100%" height="800px"> 
      </el-carousel-item>
    </el-carousel>

    <!-- 当季推荐 -->
    <div class="inSeason wi1200">
      <div class="title" style="padding-bottom: 30px;">
          <p class="titleOne" id="index">当季推荐
            <span class="hotImg">HOT</span>
          </p>
          <p class="titleTwo">我们提供专业的产品推荐服务</p>
      </div>
      <el-carousel 
        :interval="4000" 
        type="card" 
        height="420px" 
        indicator-position="none"
        @change="inSeasonIndex">
        <el-carousel-item v-for="(item,index) in isSeasonList" :key="index">
          <img v-lazy="item.imageUrl" alt="" height="300px">
          <div class="inSeasonText" v-if="activeIndex == index">
            <p>{{ item.description }}</p>
            <p class="inSeasonDetails"  @click="consult">了解详情</p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div >
      <img class="centerImg wi1200" src="http://image.yabokou.com/image/8a86927f-96c4-47ca-b47e-1ca43dfa6c38.jpg" alt="">
      <div class="recommend wi1200">
        <div id="swiper">
            <div class="imgBox flex">
                <div class="recommendBox" v-for="(item,index) in displayedProducts" :key="index">
                  <img v-lazy="item.mainUrl" alt="" style="width: 270px;height: 235px;">
                  <p class="productName">{{ item.productName }}</p>
                  <p>¥{{ item.productPrice }}</p>
                </div>
            </div>
        </div>
      </div>
    </div>

    <!-- 新闻咨询 -->
    <div class="journalismBox wi1200">
      <div class="title" style="padding-bottom: 30px;">
          <p class="titleOne" id="index">公司动态</p>
          <p class="titleTwo">来了解我们公司最新的新闻吧</p>
      </div>

      <div class="journalismList sbFlex">
        <router-link :to="'./newsDetails?id=' + item.id" class="journalism" v-for="(item,index) in newsList" :key="index">
          <img v-lazy="item.imageUrl" alt="">
          <div class="newsBox">
            <p class="sbFlex">{{ item.title }}<i class="el-icon-right"></i></p>
            <p class="newsText">{{ formatDateToOnlyDate(item.createTime) }}</p>
          </div>
        </router-link>
      </div>
    </div>
    <footerBox />
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import navbar from '../components/navbar.vue'
import footerBox from '../components/footer.vue'
import { getBannerList, getNewsList, recommendList,recommendationsList } from '../api/index'
export default {
  name: 'index',
  components: {
    navbar,
    footerBox
  },
  data() {
    return {
      bannerList: [
        { imageUrl: require('@/assets/images/index/bannerOne.jpg') },
        { imageUrl: require('@/assets/images/index/bannerThree.jpg') },
      ],
      isSeasonList: [],
      newsList: [],
      activeIndex: 0, //当前显示的主推荐
      isFixed: 0,
      newsList: [
        {
          imageUrl: ''
        }
      ],
      productList: []
    }
  }, 
  computed: {
    displayedProducts() {
      return this.productList.slice(0, 4);
    }
  },
  mounted() {
    this.$nextTick(() => {
      
    })
  },
  created() {
    //添加滚动监听事件
    //在窗口滚动时调用监听窗口滚动方法
    window.addEventListener('scroll', this.windowScrollListener);
    // this.getBanner()
    this.getRecommendations()
    this.getNews()
    this.getProductList()
  },
  methods: {
    formatDateToOnlyDate(dateTimeStr) {  
        // 创建一个 Date 对象
        const date = new Date(dateTimeStr);
        const year = date.getFullYear();  
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 使用 padStart 来确保月份是两位数  
        const day = String(date.getDate()).padStart(2, '0'); // 使用 padStart 来确保日期是两位数  
      
        // 拼接成只包含日期的字符串  
        return `${year}-${month}-${day}`;
    },
    // 获取轮播图
    getBanner() {
      getBannerList().then(response => {
        this.bannerList = response.rows
      })
    },
    getRecommendations() {
      recommendationsList().then(res => {
        console.log(res)
        this.isSeasonList = res.rows
      })
    },
    // 获取公司动态
    getNews() {
      getNewsList().then(response => {
        this.newsList = response.rows
      })
    },
    // 获取当季热销产品
    getProductList() {
      const that = this
      recommendList().then(response => {
        console.log(response)
        // response.data.imageUrl = response.rows.imageUrl.split(',')
        that.productList = response.rows
      })
    },
    inSeasonIndex(e) {
      this.activeIndex = e
    },
    
    consult() {
      this.$router.push({ name: 'relation' });  
    }
  },
}
</script>

<style scoped>
  .hello {
    width: 100%;
  }
  .banner {
    width: 100%;
    margin-top: 60px;
    animation: fadeIn;
    animation-duration: 2s;
  }
  .title {
    text-align: center;
    animation: fadeInUp;
    animation-duration: 2s;
  }
  .titleOne {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 40px;
      color: #1E1E1E;
  }
  .titleTwo {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #7A8799;
      line-height: 44px;
  }

  /* 当季推荐 */
  .inSeason {
    padding: 50px 0;
    height: auto;
    margin: auto;
    animation: fadeInUp;
    animation-duration: 2s;
  }
  .inSeasonText {
    padding: 20px 0;
  }
  .inSeasonDetails {
    padding: 10px 0;
    width: 200px;
    margin: 20px auto;
    background: #333333;
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: bold;
  }
  .inSeasonDetails:hover {
    cursor: pointer;
    box-shadow: 0px 0px 11px 2px #F4F5F6;
  }
  /* hot图标 */
  .hotImg {
      background: #d51b51;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      font-size: 16px;
      line-height: 13px;
      height: 13px;
      display: inline-block;
      padding: 4px 6px;
      margin: -18px -3px 0 -5px;
      vertical-align: middle;
      color: #FFFFFF;
  }

  .centerImg {
    max-width: 940px;
    /* height: 400px; */
    animation: fadeInUp;
    animation-duration: 2s;
    cursor: pointer;
    position: relative;
    z-index: 9;
    object-fit:cover
  }
  /* 第二个推荐产品 */
  .recommend {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    animation: fadeInUp;
    animation-duration: 2s;
    margin-top: 30px;
    overflow-x: auto;
  }
  .recommendBox {
    cursor: pointer;
    width: 300px;
    height: auto;
    margin: auto;
  }
  .recommendBox:hover {
    animation: pulse;
    animation-duration: 2s;
    z-index: 0;
  }
  .productName {
    color: black;
    font-size: 24px;
    padding: 10px;
  }

  /* 新闻资讯 */
  .journalismBox {
    height: auto;
    padding: 30px 50px;
    margin: 30px auto 0;
    animation: fadeInUp;
    animation-duration: 2s;
    padding-bottom: 240px;
  }
  .journalismList {
    height: auto;
    padding-bottom: 30px;
  }
  .journalism {
    color: #333333;
    margin-bottom: 20px;
  }
  .journalism:hover {
    cursor: pointer;
    animation: pulse;
    animation-duration: 2s;
  }
  .journalism img {
    width: 250px;
    height: 135px;
    margin-right: 20px;
  }
  .newsBox {
    padding-top: 5px;
    text-align: left;
    width: 250px;
  }
  .newsText {
    padding-top: 5px;
    font-size: 14px;
  }
@media screen and (max-width: 940px) {
  .journalismBox {
    height: auto;
    padding: 30px 50px;
    margin: 30px auto 0;
    animation: fadeInUp;
    animation-duration: 2s;
    padding-bottom: 240px;
    width: calc(100vh - 120px);
  }
  .journalismList {
    height: auto;
    padding-bottom: 30px;
  }
  .journalism {
    color: #333333;
    margin-bottom: 20px;
  }
  .journalism:hover {
    cursor: pointer;
    animation: pulse;
    animation-duration: 2s;
  }
  .journalism img {
    width: 250px;
    height: 135px;
    margin-right: 20px;
  }
  .newsBox {
    padding-top: 5px;
    text-align: left;
    width: 250px;
  }
  .newsText {
    padding-top: 5px;
    font-size: 14px;
  }
}
</style>
