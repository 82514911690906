// src/router/index.js
import Vue from "vue";
import Router from "vue-router";
import index from "@/views/index.vue";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "index",
      component: index,
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/about/index.vue"),
    },
    {
      path: "/relation",
      name: "relation",
      component: () => import("../views/relation/index.vue"),
    },
    {
      path: "/recruitment",
      name: "recruitment",
      component: () => import("../views/recruitment/index.vue"),
    },
    {
      path: "/product",
      name: "product",
      component: () => import("../views/product/index.vue"),
    },
    {
      path: "/productDetails",
      name: "productDetails",
      component: () => import("../views/product/details.vue"),
    },
    {
      path: "/newsDetails",
      name: "newsDetails",
      component: () => import("../views/newsDetails.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = "雅泊蔻";
  next();
});

export default router;
